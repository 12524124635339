import styled from '@emotion/styled';
import {
  Accordion,
  AccordionSummary,
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  ListItem,
  IconButton,
  MenuItem,
  Button,
  List,
} from '@mui/material';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MuiAppBar from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import MoodOutlinedIcon from '@mui/icons-material/MoodOutlined';

//Desktop
const drawerWidth = 300;

const typographyStyles = (theme, menuItemName) => ({
  color: menuItemName === 'Referidos' ? theme.palette.primary.main : theme.palette.white.main,
  textAlign: 'center',
  fontSize: theme.typography.button.fontSize,
  marginLeft: '6px',
});

export const TypographyReferrals = styled(Typography)(({ theme, menuItem }) => ({
  fontSize: theme.typography.button.fontSize,
  ...(menuItem.name === 'Referidos'
    ? {
        textAlign: 'center',
        width: '100%',
        color: theme.palette.primary.main,
      }
    : typographyStyles(theme, menuItem.name)),
}));

const openedMixin = theme => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(17.5)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(17.5)} + 1px)`,
  },
});

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': {
      ...openedMixin(theme),
      padding: '0px 24px 24px 24px',
      background: theme.palette.primary.gradient,
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': {
      ...closedMixin(theme),
      padding: '0px 24px 24px 24px',
      background: theme.palette.primary.gradient,
    },
  }),
}));

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  alignItems: 'flex-start',
  justifyContent: 'center',
  height: '80px',
  backgroundColor: theme.palette.primary.main,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const DrawerHeader = styled('div')(({ theme }) => ({
  height: '80px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

export const IconButtonToolbar = styled(IconButton)(({ open }) => ({
  marginRight: 5,
  display: open && 'none',
}));

export const BoxContainer = styled(Box)({
  display: 'flex',
  height: '100vh',
  overflow: 'hidden',
});

export const AccordionWrapper = styled(Box)({
  width: '100%',
  marginTop: '6px',
});

export const IconArrowRight = styled(ChevronRightIcon)(({ theme }) => ({
  height: '40px',
  width: '40px',
  marginLeft: '24px',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    borderRadius: theme.shape.borderRadius,
  },
}));
//desktop
export const IconArrowLeft = styled(ChevronLeftIcon)(({ theme }) => ({
  color: theme.palette.white.main,
  marginTop: '24px',
  height: '40px',
  width: '40px',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    borderRadius: theme.shape.borderRadius,
  },
}));
//desktop
export const IconButtonArrow = styled(IconButton)({
  padding: 0,
  margin: 0,
});
//desktop
export const LogoImage = styled('img')({
  height: '96px',
  marginTop: '-22px',
  cursor: 'pointer',
});
//desktop
export const BoxLogo = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100px',
  width: '100%',
  marginTop: '2rem',
});
//desktop
export const ListItemButtonMenu = styled(ListItemButton)(({ theme }) => ({
  minHeight: '54px',
  justifyContent: 'initial',
  maxWidth: '247px',
  px: 2.5,
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  borderRadius: theme.shape.borderRadius,
  marginLeft: '0px',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));
//desktop
export const ListItemIconMenu = styled(ListItemIcon)({
  minWidth: 40,
  display: 'flex',
  justifyContent: 'start',
  transition: 'none',
});
//desktop
export const ListItemIcons = styled(ListItemIcon)({
  minWidth: 0,
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  left: '20px',
});
//desktop
export const IconDashboard = styled(GridViewOutlinedIcon)(({ theme }) => ({
  height: '26px',
  width: '26px',
  color: theme.palette.white.main,
  transition: 'none',
  position: 'relative',
  display: 'block',
  marginLeft: '3px',
}));
//desktop
export const IconTransactions = styled(ReceiptOutlinedIcon)(({ theme }) => ({
  height: '26px',
  width: '26px',
  color: theme.palette.white.main,
  marginLeft: '3px',
}));
//desktop
export const ArrowRight = styled(KeyboardArrowRightIcon)(({ theme }) => ({
  color: theme.palette.white.main,
  marginLeft: '-10px',
}));
//desktop
export const ListItemTextMenu = styled(ListItemText)(({ open, theme }) => ({
  marginLeft: '10px',
  whiteSpace: 'pre-wrap',
  fontSize: '16px',
  opacity: open ? 1 : 0,
  '& .MuiTypography-root': {
    fontSynthesis: 'none',
    fontWeight: theme.typography.h1.fontWeight,
    fontSize: theme.typography.mediumText.fontSize,
    color: theme.palette.white.main,
    letterSpacing: '0px',
    lineHeight: '28px',
    textDecoration: 'none',
    textTransform: 'none',
  },
}));
//desktop
export const CircleBullet = styled(Box)(({ theme }) => ({
  height: 8,
  width: 8,
  borderRadius: '50%',
  backgroundColor: theme.palette.white.main,
  marginRight: 4,
}));
//desktop
export const Circle = styled(Box)(({ theme }) => ({
  height: 8,
  width: 8,
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.dark,
  marginRight: 4,
  transition: 'background-color 0.1s',
  color: 'inherit',
}));
//desktop
export const AccordionMenu = styled(Accordion)(({ theme }) => ({
  minHeight: 48,
  px: 2.5,
  width: '100%',
  backgroundColor: theme.palette.transparent.main,
  boxShadow: 'none',
  border: 'none',
  '&:before': {
    display: 'none',
  },
  '&.MuiAccordion-root': {
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  padding: 0,
}));
//desktop
export const BoxSpinner = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
});
//desktop
export const AccordionSummaryMenu = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width: '100%',
  borderRadius: theme.shape.borderRadius,
  minHeight: '30px',
  '&.MuiAccordionSummary-root': {
    minHeight: '30px',
  },
  '& .MuiAccordionSummary-content': {
    display: 'flex',
    alignItems: 'center',
    minHeight: '30px',
    padding: '0px',
  },
  '&.Mui-expanded': {
    minHeight: '30px',
    margin: 0,
  },
  padding: '0px 20px',
}));
//desktop
export const StackAcountItem = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.action.hover,
  marginTop: '0px',
  justifyContent: 'end',
  alignItems: 'center',
}));
//desktop
export const BoxChildren = styled(Box)({
  marginTop: '80px',
  height: 'calc(100% - 80px)',
  transition: 'all 0.3s ease',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  position: 'relative',
  top: '0px',
  right: '0px',
});
//desktop
export const ListItemWrapper = styled(ListItem)({
  display: 'block',
  width: '100%',
  marginTop: '-10px',
  marginBottom: '-10px',
});
//desktop
export const ListItemWallet = styled(ListItem)(({ theme }) => ({
  marginTop: '8px',
  marginBottom: '6px',
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  borderRadius: theme.shape.borderRadius,
  height: '57px',
  gap: '10px',
}));
//desktop
export const ListItemButtonWallet = styled(ListItemButton)(({ theme }) => ({
  textTransform: 'none',
  justifyContent: 'center',
  alignItems: 'flex-start',
  px: 2.5,
  height: '100%',
  width: '50%',
  backgroundColor: theme.palette.white.main,
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor: theme.palette.white.main,
  },
}));
//desktop
export const ListItemButtonAccordion = styled(ListItemButton)(({ menuItem, theme }) => ({
  textTransform: 'none',
  justifyContent: 'flex-start',
  px: 2.5,
  borderRadius: '4px',
  marginTop: '6px',
  ...(menuItem.name === 'Referidos' && {
    justifyContent: 'center',
    alignItems: 'center',
    mt: '10px',
    mb: '4px',
    backgroundColor: theme.palette.white.main,
    height: '100%',
    width: '100%',
    textTransform: 'none',
  }),
  '&:hover': {
    backgroundColor:
      menuItem.name === 'Referidos' ? theme.palette.white.main : theme.palette.action.hover,
  },
}));
//desktop
export const TypographyWallet = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '10px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));
//desktop
export const TypographyAccordion = styled(Typography)(({ theme }) => ({
  color: theme.palette.white.main,
  marginLeft: '50px',
  fontSize: '16px',
}));
//desktop
export const TypographyWalletAmount = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.primary.dark,
  fontSize: theme.typography.button.fontSize,
}));
//desktop
export const StackMiniDrawer = styled(Stack)({
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'center',
  spacing: 0.5,
});
//desktop
export const BoxMenu = styled(Box)({
  padding: '8px',
  display: 'flex',
  flexDirection: 'column',
});
//desktop
export const BoxMenuItem = styled(Box)(({ theme }) => ({
  marginRight: '4px',
  marginLeft: '2px',
  '&:hover': {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.white.main,
    '& .circle-bullet': {
      backgroundColor: theme.palette.white.main,
    },
  },
}));
//desktop
export const BoxItem = styled(Box)(({ theme }) => ({
  marginRight: '4px',
  marginLeft: '2px',
  '&:hover': {
    borderRadius: theme.shape.borderRadius,
  },
  '&:last-of-type': {
    marginBottom: 0,
  },
}));
//desktop
export const MenuItems = styled(MenuItem)(({ theme }) => ({
  marginLeft: '8px',
  marginRight: '6px',
  marginTop: '8px',
  marginBottom: '8px',
  color: theme.palette.primary.dark,
  fontSize: theme.typography.button.fontSize,
  '&:hover': {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.white.main,
    '& .circle-bullet': {
      backgroundColor: theme.palette.white.main,
    },
    '& .typography-menu-item': {
      color: theme.palette.white.main,
    },
  },
}));
//desktop
export const TypographyMenuItem = styled(Typography)(({ marginLeft, theme }) => ({
  fontSize: theme.typography.button.fontSize,
  marginLeft: marginLeft || '0px',
  color: 'inherit',
}));
//desktop
export const TypographyMenuItemAmount = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.button.fontSize,
  mt: 0.5,
  fontWeight: 'bold',
  textAlign: 'center',
  color: 'inherit',
}));
//desktop
export const Icon = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  width: '40px',
  height: '40px',
  margin: '0px',
  marginRight: '2px',
});

export const Avatar = styled('img')({
  width: '40px',
  height: '40px',
  borderRadius: '50%',
});
//desktop
export const TooltipContent = styled(Box)(({ theme }) => ({
  margin: '8px 2px',
  padding: '6px 6px',
  backgroundColor: theme.palette.white.main,
  borderRadius: theme.shape.borderRadius,
}));
//desktop
export const TextUserTooltip = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.primary.dark,
  fontWeight: theme.typography.h1.fontWeight,
  fontSize: theme.typography.customButton.fontSize,
  letterSpacing: '0px',
  textDecoration: 'none',
  lineHeight: '20px',
}));
//desktop
export const TextEmailTooltip = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.primary.dark,
  fontWeight: theme.typography.littleText.fontWeight,
  fontSize: theme.typography.littleText.fontSize,
  letterSpacing: '0px',
  textDecoration: 'none',
  lineHeight: '20px',
  textTransform: 'none',
}));
//mobile
export const ButtonAcount = styled(IconButton)(({ theme }) => ({
  width: '40px',
  height: '40px',
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));
//mobile
export const TextSection = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  boxSizing: 'border-box',
});
//mobile
export const Text = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.white.main,
  fontWeight: theme.typography.h1.fontWeight,
  fontSize: theme.typography.customButton.fontSize,
  textDecoration: 'none',
  lineHeight: '20px',
  textTransform: 'none',
}));
//mobile
export const SupportingText = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.white.main,
  fontWeight: theme.typography.littleText.fontWeight,
  fontSize: theme.typography.microText.fontSize,
  textDecoration: 'none',
  lineHeight: '20px',
  textTransform: 'none',
}));
//mobile
export const BoxContainerMobile = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.gradient,
}));
//mobile
export const ListContainer = styled(List)({
  padding: '48px 16px',
  paddingBottom: '16px',
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
});
//mobile
export const ContentTitleSection = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: theme.shape.borderRadius,
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '12px 12px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  width: '100%',
  gap: '8px',
  justifyContent: 'space-between',
  display: 'flex',
  justifyContent: 'space-between',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));
//mobile
export const MyInvestment = styled(Typography)(({ theme }) => ({
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.white.main,
  fontWeight: theme.typography.littleText.fontWeight,
  fontSize: theme.typography.customButton.fontSize,
  letterSpacing: '0px',
  textDecoration: 'none',
  lineHeight: '126%',
  textTransform: 'none',
  marginLeft: '6px',
}));
//mobile
export const DownArrowIcon = styled(KeyboardArrowDownOutlinedIcon)(({ theme }) => ({
  color: theme.palette.white.main,
}));
//mobile
export const UpArrowIcon = styled(KeyboardArrowUpOutlinedIcon)(({ theme }) => ({
  color: theme.palette.white.main,
}));
//mobile
export const Footer = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  marginTop: '0px',
  width: '100%',
  gap: '2px',
});
//mobile
export const Internal = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'end',
  alignItems: 'flex-end',
  padding: '0px',
  boxSizing: 'border-box',
  width: '100%',
});
//mobile
export const ContentActions = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  width: '100%',
  gap: '2px',
});
//mobile
export const CardContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  borderRadius: '8px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  boxSizing: 'border-box',
  flex: '1',
  height: '38px',
  width: '100%',
  margin: '8px 0px',
}));
//mobile
export const IconMoodOutlined = styled(MoodOutlinedIcon)(({ theme }) => ({
  color: theme.palette.white.main,
  width: '24px',
  height: '24px',
}));
//mobile
export const ButtonReferrals = styled(Button)(({ theme }) => ({
  height: '100%',
  with: '100%',
  textTransform: 'none',
  fontSize: theme.typography.customButton.fontSize,
}));
//mobile
export const BoxNavigation = styled(Box)({
  position: 'fixed',
  width: '100%',
  bottom: 0,
  marginLeft: 0,
  marginRight: 0,
  zIndex: 1100,
});
