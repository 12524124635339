import { Avatar, Box, Stack, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import {
  ButtonsPrimary,
  ButtonsSecondary,
} from "../../components/ComponentApp/buttoms/ButtonsXcala";
import useGetAllAvatars from "../../hooks/useGetAllAvatars";
import { useUpdateAvatar } from "../../hooks/useUpdateAvatar";
import Spinner from "../../components/Spinner";
import ModalErrorFetch from "../../components/UI/ModalErrorFetch";
import useModal from "../../hooks/custom/useModal";
import { onRefresh } from '../../utils/refreshPage';

const AvatarSelection = ({ handleCloseModal, avatar }) => {
  const { data, error, loading } = useGetAllAvatars();
  const theme = useTheme();

  const [
    updateAvatar,
    { loading: loadingUpdateAvatar, error: errorUpdateAvatar },
  ] = useUpdateAvatar();

  const avatars = data?.getAvatars;

  const [avatarSelected, setAvatarSelected] = useState(null);

  const { isShowing, toggle } = useModal();

  const handleAvatarSelection = (index) => {
    setAvatarSelected(avatars[index]);
  };

  const handleSelectAvatar = async () => {
    try {
      if (avatar?.id !== avatarSelected?.id) {
        await updateAvatar({
          variables: { avatarId: Number(avatarSelected.id) },
        });
      }
      handleCloseModal();
    } catch (error) {
      console.error("Error al actualizar avatar:", error);
    }
  };

  if (loading || loadingUpdateAvatar) {
    return <Spinner />;
  }

  if (error || errorUpdateAvatar) {
    return (
      <ModalErrorFetch
        message="Me temo que tendras que hacerlo de nuevo.... algo salio mal"
        error={error || errorUpdateAvatar}
        open={!isShowing}
        onClose={toggle}
        refresh={onRefresh}
      />
    );
  }

  return (
    <>
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
          overflowY: { xs: "auto", sm: "auto", md: "auto", md: "unset" },
        }}
      >
        <Stack
          sx={{
            bgcolor: theme.palette.white.main,
            width: { md: "46%", xs: "100%" },
            borderRadius: "12px",
            boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1); ",
            paddingY: { md: "30px", xs: "20px" },
            paddingX: { md: "100px", xs: "20px" },
            boxSizing: "border-box",
            maxHeight: { xs: "90vh", sm: "90vh", md: "90vh", lg: "auto" },
            overflowY: { xs: "auto", sm: "auto", md: "auto", lg: "auto" },
          }}
          flexDirection="row"
          justifyContent="center"
          flexWrap="wrap"
          gap="10px"
        >
          <Box
            sx={{
              alignSelf: "flex-end",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                fontSize: theme.typography.title.fontSize,
                fontWeight: theme.typography.h1.fontWeight,
                color: theme.palette.secondary.green,
                textAlign: "center",
              }}
            >
              Seleccionar Avatar
            </Typography>
          </Box>

          {avatars?.map((path, index) => {
            const isSelected = avatarSelected?.image.includes(path?.image);
            return (
              <>
                <Stack
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    cursor: "pointer",
                  }}
                  flexBasis={{ md: "calc(20%)" }}
                  key={path.id}
                  onClick={() => handleAvatarSelection(index)}
                >
                  <Stack
                    flexDirection="row"
                    justifyContent="center"
                    width="100%"
                  >
                    <Avatar
                      sx={{
                        width: "126px",
                        height: "126px",
                        "&&:hover": {
                          border: `5px solid ${theme.palette.avatar.border}`,
                        },
                        border: isSelected
                          ? `5px solid ${theme.palette.avatar.border}` 
                          : `5px solid ${theme.palette.avatar.borderTransparent}`,
                      }}
                      src={path.image}
                    />
                  </Stack>
                </Stack>
              </>
            );
          })}
          <Stack
            sx={{ width: "100%", mt: "18px" }}
            justifyContent="center"
            alignItems="center"
          >
            <Stack flexDirection="row" gap={2}>
              <ButtonsSecondary name="Cancelar" onClick={handleCloseModal} />
              <ButtonsPrimary
                name="Aceptar"
                disabled={!avatarSelected}
                onClick={handleSelectAvatar}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default AvatarSelection;
