import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import ListItem from '@mui/material/ListItem';
import { Box, Menu, MenuItem, Stack, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { LogoXcalaWhite } from '../components/LogoXcalaWhite';
import { LayoutMenuMobile } from './LayoutMenuMobile';
import { AcountItem } from './AcountItem';
import {
  AccordionMenu,
  AccordionSummaryMenu,
  AccordionWrapper,
  AppBar,
  ArrowRight,
  BoxChildren,
  BoxContainer,
  BoxItem,
  BoxLogo,
  BoxMenu,
  BoxMenuItem,
  BoxSpinner,
  Circle,
  CircleBullet,
  Drawer,
  DrawerHeader,
  IconArrowLeft,
  IconArrowRight,
  IconButtonArrow,
  IconButtonToolbar,
  IconDashboard,
  IconTransactions,
  ListItemButtonAccordion,
  ListItemButtonMenu,
  ListItemButtonWallet,
  ListItemIconMenu,
  ListItemIcons,
  ListItemTextMenu,
  ListItemWallet,
  ListItemWrapper,
  LogoImage,
  MenuItems,
  StackAcountItem,
  StackMiniDrawer,
  TypographyAccordion,
  TypographyMenuItem,
  TypographyMenuItemAmount,
  TypographyReferrals,
  TypographyWallet,
  TypographyWalletAmount,
} from './StyledComponentsMenu';
import Spinner from '../../../Spinner';
import { useMenuHandlerDesktop } from '../../../../hooks/menu/useMenuHandlerDesktop';

const LogoX = '../../../assets/images/LogoX.png';

export const LayoutMenu = ({ children }) => {
  const {
    menuItems,
    open,
    expanded,
    anchorEl,
    anchorElDashboard,
    anchorElTransactions,
    openMenu,
    user,
    navigate,
    location,
    querys,
    loadingBalance,
    walletAvailability,
    handleClick,
    handleClickDashboard,
    handleClickTransactions,
    handleClose,
    handleDrawerOpen,
    handleDrawerClose,
    handleAccordionChange,
    handleClickHome,
    handleNavigateDashboard,
    handleNavigateTransactions,
    handleNavigate,
    handleNavigateWallet,
  } = useMenuHandlerDesktop();
  const theme = useTheme();

  if (
    location.pathname === '/' ||
    location.pathname === '/signin' ||
    location.pathname === '/signup' ||
    location.pathname === '/forgotpassword' ||
    location.pathname === '/forgotchangepassword' ||
    location.pathname === '/signupcreatepassword' ||
    location.pathname === '/signupconfirm' ||
    location.pathname === '/changeforgetpasswordsuccess' ||
    location.pathname === '/forgotpasswordconfirm' ||
    location.pathname === '/confirmAccountSuccess' ||
    /^.*\/signup\/[\w=]+$/.test(location.pathname) ||
    /^.*\/signupcreatepassword\/[\w=]+$/.test(location.pathname)
  ) {
    return <>{children}</>;
  }

  return (
    <>
      {!querys ? (
        <BoxContainer>
          <CssBaseline />
          <AppBar position='fixed' open={open}>
            <Toolbar>
              <IconButtonToolbar
                open={open}
                disableRipple={true}
                color='inherit'
                aria-label='open drawer'
                onClick={handleDrawerOpen}
                edge='start'>
                <IconArrowRight />
              </IconButtonToolbar>
            </Toolbar>
          </AppBar>

          <Drawer variant='permanent' open={open}>
            <DrawerHeader>
              <IconButtonArrow onClick={handleDrawerClose} disableRipple={true}>
                <IconArrowLeft />
              </IconButtonArrow>
            </DrawerHeader>

            <BoxLogo>
              {open ? (
                <LogoXcalaWhite handleClickHome={handleClickHome} />
              ) : (
                // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
                <LogoImage onClick={handleClickHome} src={LogoX} alt='Logo' />
              )}
            </BoxLogo>

            <List sx={{ marginTop: open ? '21px' : '0px' }}>
              <ListItem disablePadding>
                <ListItemButtonMenu
                  onClick={open ? handleNavigateDashboard : handleClickDashboard}
                  sx={{ marginTop: '12px' }}>
                  <ListItemIconMenu mr={open ? 9 : '0px'}>
                    <IconDashboard />
                  </ListItemIconMenu>
                  {open ? (
                    <ListItemTextMenu open={open} primary='Inicio' />
                  ) : (
                    <ListItemIconMenu>
                      <ArrowRight />
                    </ListItemIconMenu>
                  )}
                </ListItemButtonMenu>
                <Menu
                  anchorEl={anchorElDashboard}
                  open={Boolean(anchorElDashboard)}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                  }}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  sx={{
                    borderRadius: '8px',
                    marginLeft: '14px',
                  }}>
                  <BoxItem>
                    <MenuItems onClick={event => handleNavigateDashboard(event, 'dashboard')}>
                      Inicio
                    </MenuItems>
                  </BoxItem>
                </Menu>
              </ListItem>

              <ListItem disablePadding>
                <ListItemButtonMenu
                  onClick={open ? handleNavigateTransactions : handleClickTransactions}
                  sx={{
                    marginTop: '4px',
                  }}>
                  <ListItemIconMenu mr={open ? 9 : '0px'}>
                    <IconTransactions />
                  </ListItemIconMenu>
                  {open ? (
                    <ListItemTextMenu open={open} primary='Transacciones' />
                  ) : (
                    <ListItemIconMenu>
                      <ArrowRight />
                    </ListItemIconMenu>
                  )}
                </ListItemButtonMenu>
                <Menu
                  anchorEl={anchorElTransactions}
                  open={Boolean(anchorElTransactions)}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                  }}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  sx={{ borderRadius: '8px', marginLeft: '14px' }}>
                  <BoxItem>
                    <MenuItems onClick={event => handleNavigateTransactions(event, 'transactions')}>
                      Transacciones
                    </MenuItems>
                  </BoxItem>
                </Menu>
              </ListItem>

              <AccordionWrapper>
                <List sx={{ mb: open ? '0px' : '6px' }}>
                  {menuItems.map(menuItem =>
                    open ? (
                      <ListItemWrapper key={menuItem.name} disablePadding>
                        <AccordionMenu
                          expanded={expanded === menuItem.name}
                          onChange={handleAccordionChange(menuItem.name)}>
                          <AccordionSummaryMenu
                            expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.white.main }} />}
                            aria-controls='panel1-content'
                            id='panel1-header'>
                            <ListItemIcons>{menuItem.icon}</ListItemIcons>
                            {open && <TypographyAccordion>{menuItem.name}</TypographyAccordion>}
                          </AccordionSummaryMenu>

                          <List disablePadding>
                            {menuItem.name !== 'Caja' ? (
                              menuItem.subMenu.map(subItem => (
                                <ListItem
                                  key={subItem.btnNameSub}
                                  disablePadding
                                  display='flex'
                                  width='100%'>
                                  <ListItemButtonAccordion
                                    menuItem={menuItem}
                                    onClick={() => handleNavigate(subItem.url)}>
                                    {(menuItem.name === 'Productos' ||
                                      menuItem.name === 'Mi cuenta') && <CircleBullet />}
                                    <TypographyReferrals menuItem={menuItem}>
                                      {subItem.btnNameSub}
                                    </TypographyReferrals>
                                  </ListItemButtonAccordion>
                                </ListItem>
                              ))
                            ) : (
                              <ListItemWallet disablePadding>
                                {menuItem.subMenu.map(subItem => (
                                  <ListItemButtonWallet
                                    key={subItem.btnNameSub}
                                    onClick={handleNavigateWallet}>
                                    <TypographyWallet>
                                      {subItem.btnNameSub}
                                      <TypographyWalletAmount>
                                        {loadingBalance ? (
                                          <Spinner />
                                        ) : (
                                          walletAvailability(subItem.btnNameSub)
                                        )}
                                      </TypographyWalletAmount>
                                    </TypographyWallet>
                                  </ListItemButtonWallet>
                                ))}
                              </ListItemWallet>
                            )}
                          </List>
                        </AccordionMenu>
                      </ListItemWrapper>
                    ) : (
                      <>
                        {/* biome-ignore lint/correctness/useJsxKeyInIterable: <explanation> */}
                        <StackMiniDrawer>
                          <ListItemButtonMenu
                            onClick={event => handleClick(event, menuItem.name)}
                            sx={{ backgroundColor: theme.palette.primary.main, mt: '6px' }}>
                            <ListItemIcons>
                              {menuItem.icon}
                              <KeyboardArrowRightIcon sx={{ color: theme.palette.white.main }} />
                            </ListItemIcons>
                          </ListItemButtonMenu>
                          <Box>
                            <Menu
                              id='demo-positioned-menu'
                              aria-labelledby='demo-positioned-button'
                              anchorEl={anchorEl}
                              open={openMenu === menuItem.name}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'right',
                              }}
                              transformOrigin={{
                                vertical: 'center',
                                horizontal: 'left',
                              }}
                              sx={{
                                borderRadius: '8px',
                                ml: '12px',
                              }}>
                              {loadingBalance ? (
                                <BoxSpinner>
                                  <Spinner />
                                </BoxSpinner>
                              ) : (
                                <BoxMenu>
                                  {menuItem.subMenu.map(subItem => (
                                    <BoxMenuItem key={subItem.btnNameSub}>
                                      <MenuItem
                                        onClick={() => {
                                          if (
                                            subItem.btnNameSub === 'Caja Disponible' ||
                                            subItem.btnNameSub === 'Dinero en Tránsito'
                                          ) {
                                            handleNavigateWallet();
                                          } else {
                                            navigate(subItem.url);
                                          }
                                          handleClose();
                                        }}
                                        sx={{
                                          ...(subItem &&
                                            subItem.btnNameSub === 'Caja Disponible' && {
                                              display: 'flex',
                                              flexDirection: 'column',
                                              justifyContent: 'center',
                                            }),
                                          ...(subItem &&
                                            subItem.btnNameSub === 'Dinero en Tránsito' && {
                                              display: 'flex',
                                              flexDirection: 'column',
                                              justifyContent: 'center',
                                            }),
                                        }}>
                                        {['Productos', 'Mi cuenta'].includes(menuItem.name) && (
                                          <Circle className='circle-bullet' />
                                        )}
                                        <Stack>
                                          <TypographyMenuItem
                                            marginLeft={
                                              subItem.btnNameSub === 'Caja Disponible' ||
                                              subItem.btnNameSub === 'Dinero en Tránsito'
                                                ? '0px'
                                                : '8px'
                                            }>
                                            {subItem.btnNameSub}
                                          </TypographyMenuItem>
                                          <TypographyMenuItemAmount>
                                            {walletAvailability(subItem.btnNameSub)}
                                          </TypographyMenuItemAmount>
                                        </Stack>
                                      </MenuItem>
                                    </BoxMenuItem>
                                  ))}
                                </BoxMenu>
                              )}
                            </Menu>
                          </Box>
                        </StackMiniDrawer>
                      </>
                    ),
                  )}
                </List>
              </AccordionWrapper>
            </List>

            <StackAcountItem>
              <AcountItem user={user} open={open} />
            </StackAcountItem>
          </Drawer>

          <BoxChildren width={`calc(100% - ${open ? '240px' : '80px'})`}>{children}</BoxChildren>
        </BoxContainer>
      ) : (
        <LayoutMenuMobile>{children}</LayoutMenuMobile>
      )}
    </>
  );
};
