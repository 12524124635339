import { Divider, Grid, Stack, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useRedirect from '../../../hooks/custom/useRedirect';
import { setPortfolioSelected } from '../../../redux/portfolio';
import { modelsPortfolioChart } from '../../../utils/modelsPortfolioChart';
import { portfolioNameAndDetails } from '../../../utils/portfolioNameAndDetails';
import { handleRiskProfile } from '../../../utils/riskProfile';
import { CardsButtomGroup } from '../../XcalaComponents';
import ChartDoughnutXcala from '../../XcalaComponents/ChartDoughnutXcala';
import { HeadContainerCardPorfolio } from '../HeadContainerCardPorfolio';

const CardsProduct = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  borderRadius: '16px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'space-between',
  padding: '0px',
  boxSizing: 'border-box',
  overflow: 'hidden',
  width: '100%',
  height: '100%',
  minWidth: '316px',
  transitionDelay: '-2000',
  transitionDuration: '3000',
  boxShadow: '0px 0px 12px rgba(80, 80, 80, 0.15)',
  '&:hover': {
    boxShadow: '0px 0px 20px rgba(80, 80, 80, 0.25)',
  },
}));

const TitelCards = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  height: '81px',
});

const BottonsIconosPerfile = styled('img')({
  width: '100%',
  height: '100%',
  margin: '0px',
});

const CardsInfo = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  flexBasis: 0.2,
  flexGrow: 1,
  margin: '0px 0px 0px 13px',
  width: '100%',
});
const ExpectedTime = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.textLight.main,
  fontWeight: theme.typography.littleText.fontWeight,
  fontSize: theme.typography.littleText.fontSize,
  letterSpacing: '0px',
  lineHeight: '10px',
  textDecoration: 'none',
  textTransform: 'none',
  marginBottom: '-5px',
}));
const ReantabilityTitel = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.primary.dark,
  fontWeight: theme.typography.littleText.fontWeight,
  fontSize: theme.typography.returnText.fontSize,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  alignSelf: 'stretch',
  lineHeight: '0.9rem',
  margin: '0px',
}));

const RentabilityValue = styled(Typography)(({ theme }) => ({
  whiteSpace: 'pre-wrap',
  color: theme.palette.secondary.text,
  fontWeight: theme.typography.button.fontWeight,
  fontSize: theme.typography.h3.fontSize,
  lineHeight: '8px',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
}));

const CardPortfolioMobile = ({ successDataModelsPortfolio }) => {
  const isMediumScreen = useMediaQuery('(max-width:1407px) and (min-width:900px)');

  const { handleGoById } = useRedirect();
  const [userNotOB, setUserNotOB] = useState(false);
  const { user } = useSelector(state => state.auth);
  const theme = useTheme();

  const userRisk = user?.['custom:profile'] ? user['custom:profile'].toLowerCase() : '';

  const modelsPortfolio = modelsPortfolioChart(successDataModelsPortfolio);
  const machtPortfolioProfile = modelsPortfolio.find(
    portfolio => portfolio?.profilePortfolio.toLowerCase() === userRisk,
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const gotoPortfolioDetails = id => {
    dispatch(setPortfolioSelected(id));
    navigate('/portfolio-details');
  };

  const goToSimulator = id => {
    navigate(`/portfolio-simulation/${id}`);
  };
  const goToInvestment = id => {
    userNotOB
      ? navigate(`/portfolio-simulation/${id}/investment`)
      : navigate('/completeregistration');
  };
  const portfolioDetailsFileAs = portfolioNameAndDetails(machtPortfolioProfile.fundName);

  useEffect(() => {
    if (
      user?.['custom:checkid-sign-doc'] &&
      user['custom:checkid-sign-doc'] === 'signature_success'
    ) {
      setUserNotOB(true);
    }
  }, [user]);
  return (
    <Grid item xs={12} sm={12} md={isMediumScreen ? 6 : 4} style={{ maxWidth: '100%' }}>
      <CardsProduct spacing={2} alignItems={'center'}>
        <HeadContainerCardPorfolio
          portfolioDetailsFileAs={portfolioDetailsFileAs}
          fundPerfile={machtPortfolioProfile.profilePortfolio}
          userRisk={userRisk}
          detailsStyleDashboard={12}
        />

        <Stack
          flex={1}
          flexDirection={'row'}
          width={'93%'}
          paddingBottom={'16px'}
          gap={2}
          alignItems={'center'}>
          <TitelCards>
            <Stack height={{ md: '80px', xs: '60px' }} sx={{ marginBottom: '10px' }}>
              <BottonsIconosPerfile
                src={handleRiskProfile(machtPortfolioProfile.profilePortfolio.toLowerCase())}
              />
            </Stack>

            <CardsInfo gap={1.5}>
              <ExpectedTime>{`Participación de ${machtPortfolioProfile.amountOfFunds} Fondos`}</ExpectedTime>
              <ReantabilityTitel lineHeight={'1rem'} sx={{ lineHeight: '20px' }}>
                Retorno esperado
              </ReantabilityTitel>
              <RentabilityValue>{`${machtPortfolioProfile.rentabilityFound}%`}</RentabilityValue>
              <ExpectedTime>Anualizado a largo plazo</ExpectedTime>
            </CardsInfo>
          </TitelCards>
          <Stack width={{ md: '40%', xs: '30%' }}>
            <ChartDoughnutXcala
              dataChart={machtPortfolioProfile.dataChart}
              width={'100%'}
              height={'100%'}
              fontSize={8}
              tooltip={false}
            />
          </Stack>
        </Stack>
        <Divider variant='fullWidth' width='100%' />
        <Stack width={'93%'} paddingBottom={'16px'}>
          <CardsButtomGroup
            goToInvestment={() => goToInvestment(machtPortfolioProfile.idModels)}
            goToSimulator={() => goToSimulator(machtPortfolioProfile.idModels)}
            goToProductDetail={() => gotoPortfolioDetails(machtPortfolioProfile.idModels)}
          />
        </Stack>
      </CardsProduct>
    </Grid>
  );
};

export default CardPortfolioMobile;
