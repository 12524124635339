import { Box, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { hideNumbers } from '../../utils/hideNumbers';
import { FormatCurrency } from '../../utils/numbers';
import WrapperBackgroundBanner from '../XcalaComponents/WrapperBackgroundBanner';
import WrapperContentApp from '../XcalaComponents/WrapperContentApp';
import AvatarSection from '../myTransactions/AvatarSection';
import {
  /*   BackgroundColor, */
  HeadTitle,
} from '../myTransactions/MyNewTransactionsMuiComponents';
import CoinFloating from './CoinFloating';

const $minHeigth = '407px';

const HeaderWallet = ({ balanceClient, isWallet }) => {
  const theme = useTheme();
  const { user } = useSelector(state => state.auth);

  const userRisk = user?.['custom:profile'] ? user['custom:profile'].toLowerCase() : '';

  const {
    name: nameUser,
    email: emailUser,
    'custom:dni': userDni,
    'custom:paternal-surname': lastNameUser,
  } = user;

  const fileAs = {
    currency: 'pesos',
    fileAs: 'file as',
    fileAsComplete: 'file as complete',
    risk: 'agresivo',
    profit: '10',
  };
  return (
    <>
      <WrapperBackgroundBanner $minHeigth={$minHeigth}>
        <WrapperContentApp $minHeigth={$minHeigth} fileAs={fileAs}>
          <Stack
            direction={{ md: 'row', xs: 'column' }}
            justifyContent={'center'}
            alignItems={{ md: 'center', xs: 'center' }}
            width={{ md: '50%', xs: '100%' }}
            gap={1}>
            <AvatarSection userRisk={userRisk} />
            <Box>
              <HeadTitle textAlign={{ md: 'left', xs: 'center' }} fontSize={'26px'}>
                {`${nameUser[0]?.toUpperCase() + nameUser.slice(1)} ${
                  lastNameUser[0]?.toUpperCase() + lastNameUser.slice(1)
                } `}
              </HeadTitle>
              <Typography
                color={theme.palette.white.main}
                fontWeight={'100'}
                textAlign={{ md: 'left', xs: 'center' }}
                fontSize={theme.typography.mediumText.fontSize}>
                {emailUser}
              </Typography>
              <Typography
                color={theme.palette.white.main}
                fontWeight={'100'}
                textAlign={{ md: 'left', xs: 'center' }}
                fontSize={theme.typography.mediumText.fontSize}>
                {hideNumbers(userDni)}
              </Typography>
            </Box>
          </Stack>

          <Stack
            width={{ md: '50%', xs: '100%' }}
            justifyContent={{ xs: 'center', sm: 'center' }}
            textAlign={'center'}>
            <Typography
              variant='h4'
              color={theme.palette.primary.textLightBlue}
              fontSize={theme.typography.largeText.fontSize}
              fontWeight='bold'>{`${FormatCurrency(balanceClient?.totalAmount)}`}</Typography>
            <Typography
              fontWeight={500}
              textAlign={{ xs: 'center', sm: 'center' }}
              color={theme.palette.white.main}>
              Total en Caja
            </Typography>
          </Stack>
        </WrapperContentApp>
      </WrapperBackgroundBanner>

      {isWallet === true ? (
        <Box marginTop='-120px'>
          <CoinFloating balanceClient={balanceClient} />
        </Box>
      ) : null}
    </>
  );
};

export default HeaderWallet;
