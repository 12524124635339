import { Button, Stack, Typography, styled } from '@mui/material';

const BodyNotification = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignContent: 'space-between',
  padding: '0px 16px',
  boxSizing: 'border-box',
  height: '100%',
});

const ContentTextBody = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignContent: 'space-between',
  boxSizing: 'border-box',
  height: '100%',
  margin: '0px',
});

const TextTitleDestacado = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.primary.dark,
  fontWeight: theme.typography.h4.fontWeight,
  letterSpacing: '0px',
  textDecoration: 'none',
  lineHeight: '111.00000143051147%',
  textTransform: 'none',
  alignSelf: 'stretch',
  margin: '0px',
}));
const DescriptionText = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.textLight.main,
  fontWeight: theme.typography.littleText.fontWeight,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  alignSelf: 'stretch',
  margin: '16px 0px 0px 0px',
}));

const SetTowBotton = styled(Stack)({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '16px 0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  gap: '16px',
  flexWrap: 'wrap',
});

const ButtonNotification = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  boxShadow: '0px 1.2875816822052002px 2.5751633644104004px rgba(16, 24, 40, 0.05)',
  border: `1.2875816822052002px solid ${theme.palette.primary.light}`,
  boxSizing: 'border-box',
  borderRadius: '10.300653457641602px',
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '18px 26px',
  overflow: 'hidden',
  width: { xs: '100%', sm: '' },
  height: '58px',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));

const TextBtn = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.white.main,
  fontWeight: theme.typography.button.fontWeight,
  fontSize: 'clamp(0.85rem, 0.0344rem + 3.0534vw, 1.1rem)',
  letterSpacing: '0px',
  textDecoration: 'none',
  lineHeight: '26px',
  textTransform: 'none',
  margin: '0px',
}));

const ButtonSecundary = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  color: 'rgba(105, 65, 198, 1)',
  boxShadow: '0px 1.2875816822052002px 2.5751633644104004px rgba(16, 24, 40, 0.05)',
  border: `1.2875816822052002px solid ${theme.palette.white.main}`,
  boxSizing: 'border-box',
  borderRadius: '10.300653457641602px',
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '18px 26px',
  overflow: 'hidden',
  height: '58px',
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.white.main,
  },
}));
const TextBtnSecundary = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  fontWeight: theme.typography.button.fontWeight,
  fontSize: 'clamp(0.85rem, 0.0344rem + 3.0534vw, 1.1rem)',
  letterSpacing: '0px',
  textDecoration: 'none',
  lineHeight: '26px',
  textTransform: 'none',
  margin: '0px',
}));

export const TextBodyNotification = ({ handlerClickCloses, title, textContent, btn1, btn2 }) => {
  return (
    <BodyNotification>
      <ContentTextBody>
        {title !== '' && (
          <TextTitleDestacado sx={{ fontSize: 'clamp(1.875rem, 0.0529rem + 3.4615vw, 2.8rem)' }}>
            {title}
          </TextTitleDestacado>
        )}
        <DescriptionText sx={{ fontSize: 'clamp(1.175rem, 0.7837rem + 0.3846vw, 2.2rem)' }}>
          {textContent}
        </DescriptionText>
      </ContentTextBody>
      <SetTowBotton>
        {btn2.text && (
          <ButtonSecundary
            onClick={btn2.handleSubmitReport}
            sx={{ width: { xs: '100%', md: 'auto' } }}>
            <TextBtnSecundary>{btn2.text}</TextBtnSecundary>
          </ButtonSecundary>
        )}
        <ButtonNotification sx={{ width: { xs: '100%', md: 'auto' } }} onClick={btn1.refresh}>
          <TextBtn>{btn1.text}</TextBtn>
        </ButtonNotification>
      </SetTowBotton>
    </BodyNotification>
  );
};
