import { Box, Container, Stack, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Spinner from "../../../components/Spinner";
import { ErrorStepSign } from "../../../components/UI/ErrorStepSign";
import { SuccessStepSign } from "../../../components/UI/SuccessStepSign";
import { HeaderSignedVerification } from "../../../components/completeOmborarding/HeaderSignedVerification";
import { updateUserAtributes } from "../../../redux/auth";
import useRegisterUserFinfolio from "../../../hooks/useRegisterUserFinfolio";
import useSignFetchTransactions from "../../../hooks/useSignFetchTransactions";

const SignedDocumentVerification = () => {
  const theme = useTheme();
  const [
    doSignFetchTransactions,
    {
      data: successSignFetchTransactions,
      error: errorSignFetchTransactions,
      loading: loadingSignFetchTransactions,
    },
  ] = useSignFetchTransactions();

  const [
    doRegisterUserFinfolio,
    {
      loading: loadingRegisterUserFinfolio,
      error: errorRegisterUserFinfolio,
      data: successRegisterUserFinfolio,
    },
  ] = useRegisterUserFinfolio();

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const transaction_id = searchParams.get("transaction_id");
  const { user } = useSelector((state) => state.auth);
  const onBoardingData = useSelector((state) => state.onboarding.data);
  const getValue = (name) => {
    const obj = onBoardingData?.detail?.find((item) => item.Name === name);
    return obj?.Value ? obj.Value : "";
  };
  const userRisk = user?.["custom:profile"]
    ? user["custom:profile"].toLowerCase()
    : "";
  const {
    name: nameUser,
    email: emailUser,
    "custom:dni": userDni,
    "custom:paternal-surname": lastNameUser,
  } = user;

  const transactionState =
    successSignFetchTransactions?.SignFetchTransactions?.state;

  useEffect(() => {
    if (transaction_id) {
      const data = {
        transaction_id,
      };
      doSignFetchTransactions({
        variables: {
          data,
        },
      });
    }
  }, [transaction_id, doSignFetchTransactions]);

  useEffect(() => {
    if (successRegisterUserFinfolio && !loadingRegisterUserFinfolio) {
    }
  }, [successRegisterUserFinfolio, loadingRegisterUserFinfolio]);

  useEffect(() => {
    if (errorRegisterUserFinfolio && !loadingRegisterUserFinfolio) {
    }
  }, [errorRegisterUserFinfolio, loadingRegisterUserFinfolio]);

  useEffect(() => {
    if (!loadingSignFetchTransactions && successSignFetchTransactions) {
      dispatch(
        updateUserAtributes({
          "custom:checkid-sign-doc": successSignFetchTransactions.state,
        })
      );
    }
  }, [successSignFetchTransactions, loadingSignFetchTransactions, dispatch]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (
      !loadingSignFetchTransactions &&
      !errorSignFetchTransactions &&
      transactionState === "signature_success"
    ) {
      const AddressHome = {
        Address1: getValue("address"),
        City: getValue("custom:city").name,
        Country: getValue("custom:nationality").name,
      };

      const data = {
        Name: user.name,
        FederalTaxID: user["custom:dni"],
        First: user["custom:paternal-surname"],
        Last: user["custom:maternal-surname"],
        FileAs: user.email,
        EMail: user.email,
        Suffix: user.name,
        JobTitle: getValue("custom:profession").name,
        AddressHome: AddressHome,
      };

      doRegisterUserFinfolio({
        variables: {
          data,
        },
      });
    }
  }, [
    /*successSignFetchTransactions,*/
    /*    loadingSignFetchTransactions,
    errorSignFetchTransactions,*/
    user,
    /*doRegisterUserFinfolio,*/
  ]);

  return (
    <>
      <HeaderSignedVerification
        userRisk={userRisk}
        nameUser={nameUser}
        emailUser={emailUser}
        userDni={userDni}
        lastNameUser={lastNameUser}
      />

      <Box marginTop="-120px">
        <Container
          maxWidth="1380px"
          sx={{
            marginTop: "0",
            alignItems: "center",
            background: "#F2F2F2",
            paddingY: "60px",
          }}
        >
          <Stack
            sx={{
              width: { md: "100%", xs: "100%" },
              maxWidth: "600px",
              isolation: "isolate",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0px 0px 10px 5px rgba(0, 0, 0, 0.081)",
              bgcolor: theme.palette.white.main,
              borderRadius: "8px",
              padding: "32px 8px",
              gap: 6,
              margin: "0 auto",
            }}
          >
            {loadingSignFetchTransactions && <Spinner />}

            {errorSignFetchTransactions && <ErrorStepSign />}

            {successSignFetchTransactions &&
              transactionState === "validation_failed" && (
                <ErrorStepSign validationFailed={"validation_failed"} />
              )}

            {successSignFetchTransactions &&
              transactionState === "signature_success" && <SuccessStepSign />}
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export default SignedDocumentVerification;
